// Large devices (desktops, less than 1200px)
@media (max-width: 1366px) {
    .banner-content {
        h1 {
            font-size: 60px;
        }
        h2 {
            font-size: 40px;
            line-height: 60px;
        }
        p {
            font-size: 16px;
        }
    }
}
@media (max-width: 1351px) {
    .banner-content {
        h1 {
            font-size: 70px;
        }
        p {
            font-size: 16px;
        }
    }
}

@media (max-width: 1199px) {
    .mood-nav .icon-wrapper .nav-icons {
        margin: 0 15px 0 0;
    }

    .mood-nav .icon-wrapper .nav-social li {
        // padding: 4px;
        // margin-right: 15px;
    }
}

@media (max-width: 1150px) {
    .banner-content {
        h1 {
            font-size: 50px;
        }
        h2 {
            font-size: 30px;
            line-height: 45px;
        }
        p {
            font-size: 16px;
        }
    }
}
// (desktops, less than 1200px)
@media (max-width: 1024px) {
    .banner-content {
        h1 {
            font-size: 40px;
        }
        p {
            font-size: 13px;
        }
        a {
            font-size: 17px;
            padding: 11px 22px;
        }
    }
    .mood-info-box .inf-box-wrapper .content-wrapper .content-text {
        padding: 15px 25px;
    }

    .footer .footer-top .widgets .br-rt-2 {
        border-right: none;
    }
    .footer .footer-top .br-rt-2 {
        border-right: none;
    }
}
// Medium devices (tablets, less than 992px)
@media (max-width: 991px) {
    .footer-top .container {
        width: 100%;
        max-width: 100%;
    }
    .footer .footer-top .footer-info-wrapper {
        padding: 15px 0px 0;
    }
    .footer .footer-top .quick-links-wrapper {
        padding: 15px 0px 0;
    }
    .navbar-light .navbar-nav .nav-link {
        padding: 10px;
    }
    .header {
        .navbar-brand {
            img {
                max-width: 150px;
            }
        }
        .container {
            padding: 0 15px;
        }
    }
    .navbar-toggler:hover {
        background: transparent;
        border: transparent;
    }
    .navbar-toggler:focus {
        outline: none;
    }
    .mood-nav {
        padding: 5px 10px;
        background-color: #f1f1f1;
        .nav-icons {
            display: inline-flex;
            align-items: center;
            list-style: none;
            padding: 0;
            margin-right: 16px;
            margin-bottom: 0;
            li {
                font-size: 20px;
                margin-right: 10px;
                &.shopping-cart {
                    font-size: 24px;
                    position: relative;
                    a {
                        color: #000000;
                    }
                    span {
                        position: absolute;
                        top: 1px;
                        border-radius: 50%;
                        background: #000000;
                        color: #ffffff;
                        padding: 3px;
                        font-size: 12px;
                        width: 22px;
                        height: 22px;
                        line-height: 1.3;
                        text-align: center;
                        margin-right: 40px;
                    }
                }
            }
        }
    }
    .navbar-collapse.collapse.show {
        .nav-link {
            //padding: 1rem 1.2rem;
        }
        .icon-wrapper {
            //display: none !important;
        }
    }
    .mood-page-intro {
        padding: 90px 0;
        .content-wrapper {
            padding: 40px 15px;
            p {
                padding: 0;
            }
        }
    }
    .mood-info-box {
        .inf-box-wrapper {
            margin-bottom: 30px;
            &.middle {
                flex-direction: column;
            }
        }
    }
    .mood-products {

        padding: 70px 0;
        .product-wrapper {
            margin-bottom: 55px;
        }
    }

    .mood-testimonial {
        padding: 70px 0;
    }

}
@media (max-width: 922px) {
    .banner-content {
        top: 12%;
    }
}
@media (max-width: 768px) {
    .banner-content {
        top: 11%;
        left: 4%;
        width: 50%;
        p {
            margin-bottom: 30px;
        }
    }
    .mood-page-intro {
        .img-wrapper {
            text-align: center;
        }
    }
}
@media (max-width: 767px) {
    .navbar-light .navbar-nav .nav-link {
        padding: 10px;
    }
    .features-control-item {
        .feature {
            display: flex;
            align-items: center;
        }

        .feature-heading {
            font-size: 18px;
        }

        &.active {
            .ia-icon {
                transform: rotateY(360deg);
                transform-style: preserve-3d;
                transition: all .65s ease;
            }
        }
    }

    .section-title {
        font-size: 40px;
    }

    .btn-lg, .btn-group-lg > .btn, .btn-group-lg > .btn {
        min-width: 204px;
        margin-bottom: 5px;
    }

    .mood-page-intro {
        padding: 50px 0;
        .content-wrapper {
            text-align: center;
        }
    }

    /*footer*/
    .footer {
        .footer-top {
            .footer-col-1 {
                .footer-logo-part {
                    padding-bottom: 20px;
                    flex-direction: column;
                }
                padding: 15px 0 25px 0;
                flex-direction: column;
            }
        }
    }


}
//(landscape phones, less than 768px)

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 722px) {
    .banner-content {
        width: 70%;
    }
}
// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575px) {
    .section-title {
        font-size: 30px;
    }

    .banner-content {
        top: 20%;
        h1 {
            font-size: 30px;
            margin-bottom: 20px;
        }
        p {
            display: none;
        }
        a {
            font-size: 17px;
            padding: 11px 22px;
        }
    }
}
@media (max-width: 575px) {
    .header .navbar-brand img {
        max-width: 105px;
    }
    .banner-content {
        top: 13%;
        h1 {
            font-size: 30px;
            margin-bottom: 20px;
        }
        a {
            font-size: 13px;
            padding: 11px 22px;
        }
    }
    .mood-page-intro {
        .content-wrapper {
            text-align: center;
            h2 {
                font-size: 30px;
            }
            p {
                font-size: 16px;
            }
        }
    }
}
@media (max-width: 475px) {
    .banner-content h2 {
        font-size: 25px;
        line-height: 38px;
        margin-bottom: 16px;
        margin-top: -10px;
    }
}

@media (max-width: 414px) {
    .banner-content {
        width: 60%;
    }
    .banner-content h2 {
        font-size: 16px;
        line-height: 28px;
    }
}
@media (max-width: 375px) {
    .banner-content {
        width: 57%;
        h1 {
            font-size: 27px;
        }
    }
    .banner-content h2 {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 16px;
        margin-top: -10px;
    }
}
@media (max-width: 320px) {
    .banner-content {
        top: 10%;
        width: 64%;
        h1 {
            font-size: 22px;
            margin-bottom: 20px;
        }
       h2 {
            font-size: 12px;
        }
        a {
            font-size: 13px;
            padding: 11px 22px;
        }
    }
}
//(portrait phones, less than 576px)
@media only screen and (max-width: 575px) {
    .order-xs-1{
        order: 1;
    }
    .order-xs-2{
        order: 2;
    }
    .product-details h1.product-title{
        font-size: 18px !important;
        line-height: 28px;
    }
}
body {
  color: black;
}

/*button*/
button,
input[type="button"],
input[type="submit"],
.btn {
  padding: 9px 20px;
  transition: all 0.2s;
  border-radius: 3px;
  border: solid 1px #ccc;
}
.btn-lighter {
  border-color: #ccc;
  border-color: rgba(0, 0, 0, 0.15);
  background-color: transparent;
}
.btn-primary.btn-grey {
  background-color: #eaeaea;
  color: #666;
}
.btn-lg,
.btn-group-lg > .btn {
  padding: 17px 40px;
  font-size: 17px;
}
button,
input[type="button"],
input[type="submit"] {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  white-space: nowrap;
  padding: 9px 20px;
  user-select: none;
  color: #666;
  background-color: rgba(255, 255, 255, 0);
  line-height: 1.42857143;
  outline: none;
}
input[type="submit"] {
  background-color: $tmpcolor;
  color: #fff;
}
.btn-primary,
input[type="submit"] {
  border: 0px;
  padding: 12px 20px;
  box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.25);
  background-color: $tmpcolor;
}
.btn-primary.btn-lg {
  padding: 18px 40px;
  border-radius: 6px;
}
.btn-slg,
.btn-primary.btn-slg {
  padding: 20px 40px;
}
button:hover,
input[type="button"]:hover,
input[type="submit"]:hover,
.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default,
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  color: #fff;
  background-color: #666666;
  border-color: #666666;
}
.dark-div .btn-default,
.dark-div button,
.dark-div input[type="button"],
.dark-div input[type="submit"] {
  color: #fff;
  border-color: #fff;
}
.dark-div button:hover,
.dark-div input[type="button"]:hover,
.dark-div input[type="submit"]:hover,
.dark-div .btn-default:hover,
.dark-div .btn-default:focus,
.dark-div .btn-default:active,
.dark-div .btn-default.active,
.dark-div .open .dropdown-toggle.btn-default,
.dark-div .btn-primary:hover,
.dark-div .btn-primary:focus,
.dark-div .btn-primary:active,
.dark-div .btn-primary.active,
.dark-div .open .dropdown-toggle.btn-primary {
  color: #343434;
  background-color: #fff;
  border-color: #fff;
}
button .fa-angle-right,
.btn .fa-angle-right {
  margin-left: 4px;
}

.store-button {
  display: inline-block;
  margin: 5px;
  @extend .transition;

  &.apple-btn {
    img {
      width: 220px;
    }
  }

  // img {
  // 	max-height: 40px;
  // 	width: auto;
  // }
}

.ctg-services-container {
  padding: 100px 0 0 0;

  @media only screen and (max-width: 768px) {
    padding: 20px 0;
  }

  .ctg-services-container-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 5px;
    padding: 20px 0 0 0;
    background-color: #f7edf1;
    position: relative;
    overflow: hidden;

    @media only screen and (max-width: 768px) {
      margin: 0 0 20px 0;
    }

    &::before {
      content: "";
      position: absolute;
      height: 5px;
      width: 5px;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      background-color: #f7edf1;
      transition: 0.5s;
      z-index: 1;
      border-radius: 5px;
    }

    &:hover {
      &::before {
        width: 97%;
        height: 71%;
        bottom: 44px;
        background-color: rgba(255, 255, 255, 0.33);
      }
    }

    .ctg-services-logo {
      max-width: 200px;
      margin: 15px;
      position: relative;
      z-index: 2;
    }

    h3 {
      background-color: #7d0633;
      width: 100%;
      text-align: center;
      margin: 25px 0 0 0;
      border-radius: 0 0 5px 5px;
      color: #fff;
      font-size: 18px;
      padding: 12px 0;
    }

    a {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: transparent;
      z-index: 9;
      border-radius: 5px;
    }
  }
}

.mood-dropdown {
  position: relative;

  .dropdown-menu-container {
    position: absolute;
    min-width: 290px;
    height: auto;
    background-color: #7d0633;
    top: 56px;
    z-index: 1;
    border-radius: 7px;
    left: 10px;
    padding: 13px 0 10px 0;
    list-style: none;
    display: none;

    @media only screen and (max-width: 768px) {
      position: inherit;
      top: 0;
      background-color: transparent;
      padding: 0;
      display: block;
    }

    &::before {
      content: "";
      width: 15px;
      height: 15px;
      background-color: #7d0633;
      position: absolute;
      top: -4px;
      left: 25px;
      transform: rotate(45deg);

      @media only screen and (max-width: 768px) {
        display: none;
      }
    }

    li {
      margin: 0 15px 0 12px;
      padding: 0;

      @media only screen and (max-width: 768px) {
        margin: 0 15px 15px 12px;
      }

      a {
        color: #fff !important;
        padding: 3px 0px 3px 0;
        margin: 0;
        width: 100%;
        background-color: transparent !important;
        border-bottom: 1px solid transparent;
        display: block;
        font-weight: normal;
        font-size: 16px;

        @media only screen and (max-width: 768px) {
          color: #010101 !important;
          font-size: 13px;
          border-bottom: 0px solid transparent;
        }

        &:hover {
          border-bottom: 1px solid #fff;
        }
      }
    }
  }

  &:hover {
    .dropdown-menu-container {
      display: block;
    }
  }
}



.welcome-content{
  text-align: center;
  width: 600px;
  margin: 0 auto 70px auto;

  @media only screen and (max-width: 991px) {
    width: 100%;
    padding: 0 20px;
  }

  h4{
    font-size: 30px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 0 45px 0;
    color: #202020;
  }

  p{
    font-size: 16px;
    color: #505050;
    line-height: 30px;
    font-weight: 400;
  }
}
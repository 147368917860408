.homeSlider {
    background-image: url("../../img/slider/bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    height: 680px;

    .home-slider-container {
        width: 1280px;
        margin: auto;

        .slick-list {
            padding: 0;
            margin: 0;
        }
    }
}

// .home-slider-container {
//     border: 2px solid red;
//     width: 1280px;
//     margin: auto;
//     display: flex;
//     align-items: center;
//     justify-content: center;

//     .slick-list {
//         // margin: 0;
//         // padding: 0;
//     }
// }

.home-banner-container {
    background-image: url("../../img/slider/bg.png");
    background-repeat: no-repeat;
    background-size: auto;
    background-position: top center;
    margin: 0 0 90px 0;

    .home-banner-content {
        width: 1280px;
        margin: auto;
        padding: 152px 0 0 0;

        @media only screen and (max-width: 991px) {
            width: 100%;
        }

        .content-list {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            @media only screen and (max-width: 991px) {
                display: block;
            }

            .item {
                width: 410px;
                height: auto;
                position: relative;

                @media only screen and (max-width: 991px) {
                    width: 90%;
                    margin: 0 auto 20px auto;
                }

                .signature-title{

                    img{
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                        }
                    }                   
                }

                .title-container {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    margin: auto;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .title-content {
                        position: relative;

                        &::before {
                            content: "";
                            position: absolute;
                            height: 0;
                            width: 0;
                            top: 0;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            margin: auto;
                            background-color: #f7edf1;
                            transition: 0.5s;
                            z-index: 1;
                            border-radius: 5px;
                        }

                        &:hover {
                            &::before {
                                width: 100%;
                                height: 100%;
                                bottom: 0;
                                background-color: rgba(255, 255, 255, 0.1);
                            }
                        }

                        a {
                            position: absolute;
                            display: block;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            z-index: 1;
                        }
                    }
                }
            }
        }
    }
}

/*============================
          Services Page
   ============================*/
.md-inner-banner-area {
  width: 100%;
  min-height: 250px;
  display: block;
  position: relative;
  // background-image: url("../img/inner_banner.png");
  background-size: cover;
  background-position: center center;
}

.md-inner-banner-title {
  min-height: 250px;
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  h1 {
    color: #ffffff;
    font-family: "Crimson Text", serif;
    font-size: 60px;
    line-height: 100px;
  }
}

.md-services-promo-text {
  width: 100%;
  min-height: 245px;
  display: flex;
  justify-content: center;
  align-items: center;
}

p {
  // max-width: 925px;
  // padding: 1rem;
  color: #414141;
  font-size: 16px;
  line-height: 30px;
  font-family: "Work Sans", sans-serif;
  display: inline-block;
  margin: auto;
  width: 100%;
}

//services carousal
.md-services-carousal-area {
  background: #000;
  min-height: 350px;
}

.md-services-carousal-area .slick-track .slick-slide {
  display: flex;
  min-height: 350px;
  align-items: center;
  justify-content: center;
}

//Fixme Over right slick
.services-slider {
  .slick-prev {
    left: 0;
    z-index: 2;
  }
  .slick-next {
    right: 0;
    z-index: 2;
  }

  .slick-prev:before {
    content: "\f104";
    color: #2d2d2d;
    font-size: 50px;
    font-family: "FontAwesome";
    position: relative;
    top: -15px;
  }

  .slick-next:before {
    content: "\f105";
    color: #2d2d2d;
    font-size: 50px;
    font-family: "FontAwesome";
    position: relative;
    top: -15px;
  }
}

.services-slider-single {
  text-align: center;
  border-radius: 50%;
  padding: 10px;
  display: block;

  .object-slider-content {
    transition: all 0.4s ease-in-out;
    border: 2px solid #deb44b;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-bottom: 10px;
    width: 100px;
    height: 100px;
    &:hover {
      border: 2px solid #7d0633;
      -webkit-box-shadow: inset 5px 5px 20px 15px #4d0923;
      box-shadow: inset 5px 5px 20px 15px #4d0923;
      cursor: pointer;
    }
    &.active {
      border: 2px solid #7d0633;
      -webkit-box-shadow: inset 5px 5px 20px 15px #4d0923;
      box-shadow: inset 5px 5px 20px 15px #4d0923;
      cursor: pointer;
    }
    img {
      object-fit: contain !important;
      -o-object-fit: contain !important;
      width: 60px;
      height: 60px;
      padding: 20px;
    }
  }

  strong {
    display: inline-block;
    color: #ffffff;
    text-align: center;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
  }
}

//Services Feature Content
.md-s-feature-c-area {
  overflow: hidden;
  padding-top: 50px;
  padding-bottom: 30px;
}

.md-s-feature-c-right {
  h2 {
    font-family: "Crimson Text", serif;
    font-size: 40px;
  }

  p {
    font-family: "Work Sans", sans-serif;
    font-size: 16px;
    color: #414141;
    font-weight: 400;
    padding: 0;
    margin-bottom: 1rem;
    text-align: justify;
  }

  strong {
    display: block;
    font-family: "Work Sans", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.2em;
    margin-bottom: 0.5rem;
  }

  ul {
    padding-left: 1rem;
    display: block;
  }

  li {
    font-family: "Work Sans", sans-serif;
    font-size: 16px;
    color: #414141;
    font-weight: 400;
    padding: 0;
    text-align: justify;
  }
}

a.md-c-btn {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 18px;
  border-radius: 35px;
  border: 2px solid #deb44b;
  color: #ffffff;
  background: #deb44b;
  padding: 18px 42px;
  display: inline-block;
}

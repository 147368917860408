.footer {
    .footer-top {
        background-image: url("../../img/footer-icon/bg.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 265px;
        display: flex;
        align-items: center;

        @media only screen and (max-width: 991px) {
            display: block;
            height: auto;
        }

        .footer-content {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media only screen and (max-width: 991px) {
                flex-direction: column;
            }

            .contact-container {

                @media only screen and (max-width: 991px) {
                    order: 2;
                    margin: 0 0 20px 0;
                }

                .address {
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;

                    i {
                        display: block;
                        margin: 0 18px 0 0;
                    }

                    div {
                        color: #ffffff;
                        margin: 0 0 10px 0;
                        font-size: 12px;

                        span {
                            font-weight: 600;
                            display: block;
                            margin: 0 0 5px 0;
                        }
                    }
                }
            }

            .office-time {

                @media only screen and (max-width: 991px) {
                    order: 3;
                    margin: 0 0 20px 0;
                }

                .time {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    color: #ffffff;
                    margin: 0 0 10px 0;
                    font-size: 12px;

                    &:last-child {
                        margin: 0;
                    }

                    i {
                        display: block;
                        margin: 0 18px 0 0;
                    }
                }
            }
        }

        .nav-social {
            display: flex;
            align-items: center;
            justify-content: center;
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                margin: 0 15px;

                a {
                    color: #ffffff;
                    transition: 0.5s;

                    &:hover{
                        transition: 0.5s;
                        color: #c5c4c4;
                    }
                }
            }
        }

        .br-rt-2 {
            border-right: 2px solid #414141;
        }
        color: $white;
        .footer-col-1 {
            padding: 15px 30px 0 0;
            display: flex;
            flex-direction: column;
            .footer-logo-part {
                display: flex;
                justify-content: space-between;
                padding-bottom: 20px;
                ul.nav-social {
                    @media only screen and (max-width: 768px) {
                        margin-top: 20px;
                        li {
                            &:nth-child(1) {
                                margin-left: 0;
                            }
                        }
                    }
                    padding: 0;
                    list-style: none;
                    display: inline-flex;
                    li {
                        margin: 0 0 0 20px;
                        a {
                            color: #deb44b;
                        }
                    }
                }
            }
            p {
                font-family: "Work Sans", sans-serif;
                font-weight: 500;
                font-size: 16px;
                line-height: 30px;
            }
        }
        .footer-info-wrapper {
            padding: 15px 30px 0;
            h3 {
                font-family: "Crimson Text", serif;
                font-size: 26px;
                margin-bottom: 30px;
            }
            p {
                font-family: "Work Sans", sans-serif;
                font-size: 16px;
                line-height: 30px;
                margin-bottom: 30px;
                span {
                    width: 75px;
                    display: inline-block;
                }
                a {
                    color: #deb44b;
                }
            }
        }
        .quick-links-wrapper {
            padding: 15px 30px 0;
        }
        .widgets {
            h3 {
                font-family: "Crimson Text", serif;
                font-size: 26px;
                margin-bottom: 32px;
            }
            ul {
                padding: 0;
                list-style: none;
                li {
                    margin-bottom: 8px;
                    a {
                        color: #deb44b;
                    }
                }
            }
            ul.links-wrapper-2 {
                padding: 0;
                list-style: none;
                li {
                    display: inline-block;
                    width: 48%;
                    vertical-align: top;
                    margin-bottom: 8px;
                    a {
                        color: #deb44b;
                    }
                }
            }
            .br-rt-2 {
                border-right: 2px solid #414141;
            }
        }
    }
    a {
        color: #deb44b;
        &:hover {
            color: #ffffff;
        }
    }
    .footer-bottom {
        font-family: "Work Sans", sans-serif;
        font-size: 11px;
        background: #000000;
        padding: 25px 0;
        color: #ffffff;
    }
}

.footer-top .container,
.footer-bottom .container {
    width: 1280px;
    max-width: 100%;
}

.totop {
    position: fixed;
    bottom: -45px;
    right: 15px;
    width: 40px;
    height: 40px;
    border-radius: 3px;
    background: #000000;
    color: $tmpcolor;
    text-align: center;
    cursor: pointer;
    line-height: 40px;
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.3);

    @extend .transition;

    button {
        padding: 0;
        background: #000000;
        color: #7d0633;

        @extend .transition;

        &:hover {
            color: #fff;
            background: lighten(#000000, 15%);
        }
    }
}
.photo_block {
    img {
        width: 100%;
    }
}

.tags {
    text-align: center;
    padding: 20px 0px;
    background-color: rgb(43, 92, 226);
}

.tag {
    outline: none;
    border: none;
    color: #eee;
    margin: 0px 10px;
    background-color: transparent;
    cursor: pointer;
}

.active {
    font-weight: bold;
    //border-bottom: 1px solid #eee;
}
.demo-container {
    width: 80%;
    margin: auto;
    display: flex;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
}

.image-card {
    padding: 5px;
    margin: 0px;
    border: 1px solid #7d0633;
    border-radius: 2px;
    &:hover {
        background-color: #7d0633;
        .image-block {
            filter: brightness(0.7);
        }
    }
}

.image {
    width: 100%;
    height: auto;
    opacity: 0;
}
.image-block {
    width: 100%;
    height: 380px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
}

@media only screen and (max-width: 767px) {
    .break-line {
        position: relative;
        width: 100%;
        display: grid;
        place-items: center;
        margin-bottom: 40px;
        span {
            position: absolute;
            width: 40%;
            height: 2px;
            background-color: #c4889e;
        }
    }
}

.eapps-instagram-feed-title {
    display: none !important;
}
/* Get in a touch */

.d-block {
    display: block;
}
.getinTouch-btn {
    position: fixed;
    right: 35px;
    //background: #7d0633;
    background: #000;
    justify-content: center;
    align-items: center;
    bottom: 35px;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.9s;
    z-index: 99999999;
    border-color: transparent;
    padding: 10px;
    &::before {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: inherit;
        border-radius: inherit;
        transition: opacity 0.2s, transform 0.2s;
        -webkit-animation: src_pulse-animation__3W6kG 1s cubic-bezier(0.24, 0, 0.38, 1.6) infinite;
        animation: src_pulse-animation__3W6kG 1s cubic-bezier(0.24, 0, 0.38, 1.6) infinite;
        z-index: -1;
    }
    img {
        width: 100%;
    }
    &:hover {
        //background: #7d0633;
        background: #000;
    }
}
@-webkit-keyframes src_pulse-animation__3W6kG {
    to {
        opacity: 0;
        transform: scale(1.5);
    }
}
@keyframes src_pulse-animation__3W6kG {
    to {
        opacity: 0;
        transform: scale(1.5);
    }
}

.bottom-navigation {
    display: flex;
    width: 25%;
    height: 180px;
    position: fixed;
    bottom: 15px;
    z-index: 999999999 !important;
    justify-content: center;
    transition: width 0.3s linear, opacity 0.3s linear;
    opacity: 1;
    right: 0;
    @media only screen and (max-width: 1024px) {
        width: 40%;
    }
    @media only screen and (max-width: 768px) {
        width: 100%;
    }
}

.bottom-navigation.Collapse,
.bottom-plate.Collapse {
    width: 0;
    opacity: 0;
    right: 20px;
}

.scroll-container {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: thin;
    scrollbar-color: #2f353d #fff;
    position: relative;
}
.scroll-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #fff;
    border-radius: 10px;
}

.scroll-container::-webkit-scrollbar {
    height: 8px;
    background-color: #fff;
    border-radius: 10px;
}
.scroll-container::-webkit-scrollbar-thumb {
    background-color: #2f353d;
    border-radius: 10px;
}

.icons-block {
    height: 100%;
    width: inherit;
    font-size: 2em;
    transition: transform 0.1s linear;
    flex-grow: 1;
    padding: 3px 0;
    flex: 1 0 25%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.icons-block:focus {
    font-size: 2em !important;
    outline: none;
}
.tactIcons_IconContent__Kq3df {
    height: 100%;
    width: 85%;
    margin-left: 7.5%;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    align-items: center;
    transition: transform 0.2s ease;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.tactIcons_IconContent__Kq3df:focus {
    outline: none;
}
.icons-block span {
    visibility: hidden;
    position: absolute;
    bottom: 110px;
    width: 85px;
    ///background-color: #7d0633;
    background-color: #000;
    padding: 4px 0;
    border-radius: 12px;
    font-size: 12px;
    text-align: center;
}
.icons-block:focus .tactIcons_Icon__2q0_0,
.icons-block:hover .tactIcons_Icon__2q0_0 {
    outline: none;
    transform: translateY(-25px);
    -webkit-box-reflect: below 0 linear-gradient(transparent, transparent);
}

.icons-block:focus .icons-reflection,
.icons-block:hover .icons-reflection {
    display: none;
}
.icons-block:focus span,
.icons-block:hover span {
    visibility: visible;
    color: #fff;
}

.icons-block a {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
}

.tactIcons_Icon__2q0_0 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    margin-bottom: 20px;
    border-radius: 35px;
    position: relative;
    //background-color: #7d0633;
    background-color: #000;
    transition: transform 0.1s ease;
    -webkit-box-reflect: below 4px -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(0.6, transparent), to(hsla(0, 0%, 100%, 0.6)));
}

.icons-reflection {
    width: 48px;
    height: 10px;
    position: relative;
    transform: translateY(-17px);
    opacity: 0.6;
}

.icons-block a:active,
.icons-block a:hover,
.icons-block a:link,
.icons-block a:visited {
    color: inherit;
    background-color: inherit;
}

.icons-block:active {
    font-size: 1em;
}
@media (min-width: 768px) {
    .icons-block span {
        font-size: 14px;
        width: 20%;
        max-width: 110px;
    }
    .icons-block {
        flex: 1 0 10%;
    }
    .icons-reflection {
        transform: translateY(-12px);
    }
}

.bottom-plate {
    height: 65px;
    width: 25%;
    position: fixed;
    bottom: 8px;
    border-radius: 35px;
    //box-shadow: -3px 20px 20px #7d0633;
    box-shadow: -3px 20px 20px #000;
    transform: rotateX(65deg) translate3d(0, -85px, -40px);
    background-color: #f0f0f0;
    transition: width 0.2s linear;
    z-index: 99999999 !important;
    right: 0;
    @media only screen and (max-width: 1024px) {
        width: 40%;
    }
    @media only screen and (max-width: 768px) {
        width: 100%;
    }
}

.Vanish {
    display: none;
}
.tactIcons_Icon__2q0_0 i {
    color: #ffffff;
    font-size: 15px;
}

.powered-by,
.powered-by a,
.copy-right {
    color: #606060;
    font-size: 11px;
    font-weight: 700;
}

/* old css */
.list-product {
  position: relative;
  background: #fff;
  z-index: 1;
  border: none;
  margin-bottom: 30px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  text-align: center;
}

.list-product .img-block {
  position: relative;
}
.clos_gap {
  @media (min-width: 360px) and (max-width: 767px) {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
}
.list-product {
  .img-block {
    img {
      width: 100%;
      // height: 220px;
      margin: 0;
      -webkit-transition: all 0.3s linear;
      transition: all 0.3s linear;
      @media (min-width: 768px) and (max-width: 1599px) {
        width: 100%;
        // height: 160px;
      }
      @media only screen and (max-width: 767px) {
        width: 100%;
        // height: auto;
      }
      @media only screen and (max-width: 449px) {
        // height: 170px;
      }
    }
  }
}

.list-product .img-block .second-img {
  position: absolute;
  opacity: 0;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  left: 0;
  max-width: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
}

.list-product .new {
  position: absolute;
  top: 15px;
  left: 15px;
  display: inline-block;
  color: #fff;
  padding: 0 5px;
  text-align: center;
  line-height: 20px;
  height: 20px;
  font-size: 12px;
  font-weight: 700;
  border-radius: 5px;
  text-transform: capitalize;
  text-align: center;
  z-index: 1;
  background: #146cda;
}

.list-product .product-decs {
  padding: 15px;
}

.list-product .product-decs a {
  font-size: 15px;
  color: #232323;
  font-weight: 700;
  text-decoration: none;
}

/* @media (min-width: 768px) and (max-width: 1599px) {
    .list-product .product-decs a {
      font-size: 12px;
    }
  } */

.list-product .product-decs h2 {
  font-weight: 400;
  margin: 0;
  line-height: 1;
}

.list-product .product-decs h2 a {
  line-height: 18px;
  display: block;
  margin-bottom: 10px;
  font-weight: 400;
  color: #47494a;
  font-size: 14px;
  text-transform: capitalize;
}

.list-product .product-decs .pricing-meta {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.list-product .product-decs .pricing-meta ul {
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.list-product .product-decs .pricing-meta ul li {
  color: #999999;
  margin-right: 5px;
  font-size: 15px;
  line-height: 30px;
  list-style: none;
}

/* @media (min-width: 768px) and (max-width: 1599px) {
    .list-product .product-decs .pricing-meta ul li {
      font-size: 12px;
    }
  } */
.off-price {
  text-decoration: line-through;
}

.list-product .product-decs .pricing-meta ul li.current-price {
  color: #7d0633;
  font-weight: 600;
  font-size: 16px;
}

.list-product .product-decs .pricing-meta ul li.discount-price {
  display: inline-block;
  vertical-align: top;
  color: #fff;
  background: #7d0633;
  padding: 0 4px;
  border-radius: 5px;
  text-align: center;
  line-height: 20px;
  height: 20px;
  font-size: 12px;
  font-weight: 700;
  text-transform: capitalize;
  text-align: center;
  z-index: 4;
  margin-left: 2px;
}

.list-product .add-to-link {
  margin-top: -35px;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 100%;
  background: #fff;
  padding: 0 15px 15px 10px;
  /* border: 1px solid #ebebeb; */
  border-top: transparent;
  border-radius: 0 0 5px 5px;
  font-size: 0;
  visibility: hidden;
  opacity: 0;
  transform: translateY(15px) translateZ(0);
  -webkit-transform: translateY(15px) translateZ(0);
  perspective: 800px;
  -webkit-perspective: 800px;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-transition: opacity 0.3s ease, visibility 0.3s ease,
    -webkit-transform 0.3s ease;
  transition: opacity 0.3s ease, visibility 0.3s ease,
    -webkit-transform 0.3s ease;
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease,
    -webkit-transform 0.3s ease;
}

.list-product .add-to-link ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.list-product .add-to-link ul li {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  line-height: 1;
  margin-right: 3px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.list-product .add-to-link a.cart-btn {
  display: inline-block;
  color: #7d0633;
  border: 1px solid #7d0633;
  padding: 0 10px;
  line-height: 40px;
  height: 40px;
  width: auto;
  cursor: pointer;
  font-size: 14px;
  position: relative;
  z-index: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 105px;
}

.list-product .add-to-link a {
  text-align: center;
  background: #ffffff;
  border: 1px solid #7d0633;
}

.list-product .add-to-link a .fa {
  font-size: 20px;
}

.list-product .add-to-link a:hover {
  background: #7d0633;
  color: #ffffff;
  text-decoration: none;
}

.list-product:hover {
  border: none;
}

/* .list-product:hover .second-img {
    opacity: 1;
    visibility: visible;
  } */

/* .list-product:hover .quick-view a {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  } */

/* .list-product:hover .pricing-meta {
    opacity: 0;
    -webkit-transform: translateY(15px);
            transform: translateY(15px);
    -webkit-transition: all .3s linear;
    transition: all .3s linear;
  } */

.list-product:hover .add-to-link {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0) translateZ(0);
  transform: translateY(0) translateZ(0);
}
.shop-category-area .shop-top-bar {
  width: 100%;
  float: right;
}
.shop-category-area {
  .page-title {
    padding-top: 50px;
  }
}
.shop-select .nice-select {
  -webkit-tap-highlight-color: transparent;
  background-color: #ffffff;
  border-radius: 25px;
  border: 2px solid #000;
  clear: both;
  cursor: pointer;
  outline: 0;
  padding: 10px 14px 14px 14px;
  position: relative;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* max-width: 135px;
    width: 150px; */
  font-size: 16px;
  color: #000;
  font-weight: 700;
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .shop-select .nice-select {
    margin-top: 30px;
    padding: 10px 25px;
  }
}

.shop-select .nice-select .list {
  background-color: #fff;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-top: 4px;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: 100%;
  left: 0;
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transform: scale(0.75) translate(-21px);
  transform: scale(0.75) translate(-21px);
  -webkit-transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25),
    opacity 0.15s ease-out;
  transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  z-index: 15;
  width: 100%;
  color: #212121;
}

.order-md-last {
  padding-right: 3rem;
}

@media (min-width: 768px) and (max-width: 1599px) {
  .order-md-last {
    padding-right: 2rem;
  }
}

.sidebar_area {
  p {
    font-size: 16px;
    line-height: 50px;
    color: #999999;
    margin: 0px;
  }
  ul {
    padding: 0;
    margin: 0;
    li {
      line-height: 40px;
      font-size: 16px;
      line-height: 30px;
      padding: 12px 20px 10px;
      font-weight: 600;
      margin: 0px;
      list-style: none;
      border-bottom: 1px solid #ececec;
      a {
        color: #afb0bd;
        text-decoration: none;
      }
      &:nth-last-child(1) {
        border: none;
      }
    }
  }
  .search-element {
    input {
      width: 100%;
      border: 2px solid #000;
      border-radius: 25px;
      height: 47px;
      line-height: 42px;
      padding: 20px 20px 20px 20px;
    }
    button {
      position: absolute;
      top: 0;
      right: 0;
      height: 45px;
      width: 45px;
      text-align: center;
      color: #fff;
      border-radius: 50%;
      border: 0;
      -webkit-transition: all 0.3s linear;
      transition: all 0.3s linear;
      background: #000;
      padding: 0;
      i {
        font-size: 20px;
        display: block;
      }
    }
  }
}

.sidebar_area .search-element button:focus,
.sidebar_area .search-element button:active,
.sidebar_area .search-element button:hover {
  outline: none;
  border: none;
}

.sidebar-widget .ui-widget-content {
  border: 1px solid #7d0633;
}

.sidebar-widget .ui-slider-horizontal {
  height: 3px;
  margin: 20px 0 20px;
}

.sidebar-widget .filter_btn {
  color: #fff;
  background-color: #7d0633;
  border: 2px solid #7d0633;
  border-radius: 50px;
  text-decoration: none;
  font-size: 16px;
  padding: 9px 40px;
}

@media (min-width: 768px) and (max-width: 1599px) {
  .sidebar-widget .filter_btn {
    font-size: 14px;
    padding: 10px 25px;
  }
}

.sidebar-widget .filter_btn:hover {
  color: #fff;
  border: 2px solid #deb44b;
  background: #deb44b;
}

/* old css end */

/* new css */
.pages-description-wrapper {
  min-height: 400px;
}
.page-title h2 {
  font-size: 40px;
  color: #1c1d1f;
  font-weight: 900;
  text-align: center;
  margin-bottom: 3rem;
  @media only screen and (max-width: 768px) {
    font-size: 34px;
  }
}
.pagination {
  li {
    margin-right: 5px;
    margin-top: 15px;
    a {
      padding: 10px 12px;
      border: 1px solid #e9ecef;
      color: #868e96 !important;
      font-weight: 600;
      @media only screen and (max-width: 450px) {
        font-size: 12px;
        padding: 8px;
      }
    }
    &:nth-child(1),
    &:nth-last-child(1) {
      a {
        border: none;
      }
    }
    &:hover {
      a {
        background-color: #e9ecef;
        color: #7d0633 !important;
      }
    }
  }
}

.pagination li.active a {
  color: #fff !important;
  background-color: #7d0633;
  border-color: #7d0633;
}

.product-rating {
  display: inline-flex;
}

.product-rating ul {
  padding-left: 0px;
}

.product-rating ul li {
  color: #7d0633;
  display: inline;
  padding-right: 10px;
}
.product-rating ul li i {
  font-size: 20px;
}
.product-rating ul li i:hover {
  cursor: pointer;
}

.product-details-add-to-cart .add-to-link ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.product-details-add-to-cart .add-to-link ul li {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  line-height: 1;
  margin-right: 3px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.product-details-add-to-cart .add-to-link a.cart-btn {
  text-decoration: none;
  display: inline-block;
  color: #7d0633;
  border: 1px solid #7d0633;
  padding: 0 10px;
  line-height: 48px;
  width: 100%;
  cursor: pointer;
  font-size: 14px;
  position: relative;
  z-index: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  border-radius: 3rem;
}
.add-to-link {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.product-details-add-to-cart .add-to-link a.cart-btn.add-to-cart {
  background: #7d0633;
  color: #ffffff;
  margin-right: 10px;
}
.product-details-add-to-cart .add-to-link a.cart-btn.add-to-cart.disable-cart {
  background: #ff0303;
  color: #ffffff;
  margin-right: 10px;
}

.product-details-add-to-cart .add-to-link a.cart-btn.add-to-cart span {
  z-index: 20;
}
.product-details-add-to-cart .add-to-link a.cart-btn.add-to-cart:after {
  background: #ffffff;
  content: "";
  height: 155px;
  left: -75px;
  opacity: 0.2;
  position: absolute;
  top: -50px;
  transform: rotate(35deg);
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
  width: 50px;
  /*z-index: -10;*/
}

.product-details-add-to-cart .add-to-link a.cart-btn.add-to-cart:hover::after {
  left: 120%;
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
}

.product-details-add-to-cart
  .add-to-link
  a.cart-btn.add-to-cart.cy-btn-transparent {
  color: #7d0633;
  margin-right: 10px;
  border-radius: 3rem;
  background: transparent;
  border: 1px solid #7d0633;
}

.product-details-add-to-cart
  .add-to-link
  a.cart-btn.add-to-cart.cy-btn-transparent
  span {
  z-index: 20;
}
.product-details-add-to-cart
  .add-to-link
  a.cart-btn.add-to-cart.cy-btn-transparent:after {
  background: #7d0633;
  content: "";
  height: 155px;
  left: -75px;
  opacity: 0.2;
  position: absolute;
  top: -50px;
  transform: rotate(35deg);
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
  width: 50px;
  /*z-index: -10;*/
}

.product-details-add-to-cart
  .add-to-link
  a.cart-btn.add-to-cart.cy-btn-transparent:hover::after {
  left: 120%;
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
}

.list-product .add-to-link a.cart-btn.active,
.product-details-add-to-cart .add-to-link a.cart-btn.active {
  background: #7d0633;
  color: #ffffff;
}
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35vh;
  width: 100%;
}
.pagination {
  justify-content: center;
  align-items: center;
}
.price-filter {
  margin: 20px 8px;
}
.price-filter .input-range__slider {
  background: #7d0633;
  border: 1px solid #7d0633;
}
.price-filter .input-range__track--active {
  background: #7d0633;
}
.all-page-title h2 {
  font-size: 40px !important;
  line-height: 60px !important;
  color: #1c1d1f;
  font-weight: 900;
  text-align: center;
  margin-bottom: 15px;
  margin-top: 15px;
  @media only screen and (max-width: 768px) {
    font-size: 36px !important;
    line-height: 48px !important;
  }
}
.coyote-btn {
  background-color: #7d0633;
  border-color: #7d0633;
  color: #fff;
}
.header-cart-icon {
  /* margin-left: 5px; */
  position: relative;
}
.header-wishlist-icon {
  margin-left: 20px;
  position: relative;
}
.header .order-cart-separation {
  padding: 0px 5px;
  font-size: 14px;
  color: #fff;
}
.header-cart-icon a,
.header-wishlist-icon a {
  color: #000 !important;
  text-decoration: none;
}
.header-cart-icon a svg {
  color: white;
  height: 12px;
}
.header-cart-icon a span.badge {
  position: absolute;
  left: 8px;
  top: -3px;
  border-radius: 50%;
  border: 1px solid white;
  font-size: 9px;
}
.header-wishlist-icon a span.badge {
  position: absolute;
  left: 13px;
  top: -3px;
}
.badge-light {
  color: #fff;
  background-color: transparent;
}
/* product details page css start */
.product-details .product-rating {
  margin-bottom: 10px;
}
.product-details .product_details_thumb_wrapper {
  position: relative;
  .slick-slider {
    .slick-list {
      .slick-slide {
        @media only screen and (max-width: 575px) {
          max-width: 30% !important;
        }
      }
    }
  }
}
.product-details .product_details_thumb_wrapper .slick-prev:before,
.product-details .product_details_thumb_wrapper .slick-next:before {
  border-radius: 50%;
  background: #000;
  color: #fff;
  font-weight: bold;
  font-size: 15px;
  display: none;
}
.product-details .product_details_thumb_wrapper .slick-prev:before {
  content: "˄";
  padding: 0px 3px 4px 4px;
}
.product-details .product_details_thumb_wrapper .slick-next:before {
  content: "˅";
  padding: 1px 3px 3px 4px;
}
.product-details .product_details_thumb_wrapper .slick-prev {
  position: absolute;
  top: -15px;
  left: 44%;
}
.product-details .product_details_thumb_wrapper .slick-next {
  position: absolute;
  top: 610px;
  left: 44%;
}
.product-details #product-details-main-img {
  cursor: zoom-in;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
}
.product-details .product_details_thumb_wrapper .slick-slide img {
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  border: 2px solid transparent;
}
.product-details .product_details_thumb_wrapper .slick-slide img:hover {
  cursor: pointer;
  border: 2px solid #7d0633;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.product-details .product_details_thumb_wrapper .slick-slide img.active {
  cursor: pointer;
  border: 2px solid #7d0633;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.product-details .option-color-img-wrapper {
  transition: all 0.1s ease-in-out;
  display: inline-block;
  width: 30px;
  height: 30px;
  position: relative;
  overflow: hidden;
  margin-right: 15px;
  border: 2px solid transparent;
  border-radius: 50%;
  cursor: pointer;
}
.product-details .option-color-img-wrapper:hover {
  display: inline-block;
  width: 30px;
  height: 30px;
  position: relative;
  overflow: hidden;
  margin-right: 15px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
}
.product-details .option-color-img-wrapper.active {
  border: 2px solid #ffffff;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
}
.product-details .option-color-img-wrapper .option-color-img {
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
}
.product-details .option-color-img-wrapper i {
  position: absolute;
  z-index: 1;
  top: 20%;
  left: 22%;
  color: #fff;
}
.option-size-area {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}
.option-size-area span + span {
  margin-left: 0.5rem;
}
.product-details .option-size-img-wrapper {
  transition: all 0.3s ease-in-out;
  text-align: center;
  width: 70px;
  border-radius: 2rem;
  padding: 0.15rem 0.3rem;
  display: inline-block;
  border: 1px solid #929292;
  cursor: pointer;
  position: relative;
}
.product-details .option-size-img-wrapper:hover {
  color: #7d0633;
  border: 1px solid #7d0633;
}
.product-details .option-size-img-wrapper.active {
  border: 1px solid #7d0633;
  font-weight: bold;
  background-color: #7d0633;
  color: #fff;
}
.product-details .option-size-img-wrapper i {
  position: absolute;
  z-index: 1;
  top: 25%;
  left: 37%;
  color: #7d0633;
}
.product-details .selected-option-value {
  font-weight: 400;
  font-size: 18px;
}
.product-details #quantity {
  text-align: center;
  width: 75px;
  display: inline;
  border-radius: 3rem;
}
.product-details .product-details-main {
  border: 2px solid #eee;
}
.product-details h1.product-title {
  font-size: 26px;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 0.6rem;
}
.product-details .regular-price {
  font-size: 20px;
  font-weight: 600;
  color: #7d0633;
  margin-bottom: 0.6rem;
}
.product-details .option-name {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 0.8rem;
}
.product-details .row.product-quantity p {
  font-size: 1rem;
  font-weight: bold;
}
/* for category list in the product archive page*/
.cat-link-box {
  margin-bottom: 30px;
  a {
    position: relative;
    p {
      color: #ffffff;
      font-size: 30px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      text-align: center;
      font-family: Crimson Text, sans-serif;
      font-weight: 400;
    }
  }
}

@media only screen and (max-width: 767px) {
  .product-details .product_details_thumb_wrapper {
    //display: none;
  }
}
@media only screen and (max-width: 399px) {
  .product-details .container.mt-5.mb-3 {
    margin-top: 15px !important;
  }
}

.cy-product-details-area {
  margin-top: 4rem;
}

.cy-product-details-content h4 {
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.cy-product-details-content p {
  font-weight: 600;
  font-size: 0.9rem;
}

.cy-product-details-content ul {
  display: list-item;
  padding-left: 1rem;
}

.cy-product-details-content ul li {
  display: list-item;
  list-style-type: disc;
}
/* product details page css end */

.product-quantity-meta p span {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 20px;
}

/*================================
        Home Page Featured Product
     ===============================*/
.featured-product-single {
  position: relative;
  overflow: hidden;
}

.featured-product-single:before {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  display: block;
  content: "";
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 100%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
}

.featured-product-single:hover::before {
  -webkit-animation: circle 0.75s;
  animation: circle 0.75s;
}

@-webkit-keyframes circle {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}
@keyframes circle {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}

.mood-testimonial .testimonial-sliders p {
  color: #fff;
}

.modal {
  z-index: 99999999;
}

.custom_modal_centered_elm {
  align-items: center;
  justify-content: center;
}

/*============================
              Product Details
     ============================*/

.product-details-title h4 {
  font-weight: 700;
  margin-bottom: 2rem;
}

/*================================
        Related Products Single
     ===============================*/
.related-products-area {
  margin-top: 4rem;
}
.related-products-title h4 {
  font-weight: 700;
  margin-bottom: 2rem;
}
.related-products-main {
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.related-p-single {
  transition: all 0.4s ease-in-out;
  width: calc(20% - 30px);
  margin: 0 15px 30px 15px;
  border-radius: 4px;
}
.related-p-single:hover {
}

@media all and (max-width: 600px) {
  .related-p-single {
    transition: all 0.4s ease-in-out;
    width: 100%;
    margin: 0 15px 30px 15px;
    border-radius: 4px;
  }
}

/* cart page css start */
.cart-page .cart-minus,
.cart-page .cart-plus,
.wishlist-page .wishlist-minus,
.wishlist-page .wishlist-plus {
  margin-top: -7px;
  font-weight: bold;
}
.cart-page .price-total {
  padding-top: 16px;
}
@media only screen and (max-width: 1199px) {
  .cart-page .cart-minus,
  .cart-page .cart-plus {
    //display: none;
  }
}
/* cart page css end */

/* login page css end */

/* wishlist page css start */
@media only screen and (max-width: 1199px) {
  .wishlist-page .add-to-cart {
    margin-bottom: 0.25rem;
  }
}
/* wishlist page css end */

/* myaccount page css start */
.myaccount-leftnav .nav-link:not(.active):hover {
  color: #7d0633;
}
.myaccount-leftnav .nav-link.active {
  background-color: #7d0633;
}
.print_btn i {
  font-size: 20px;
  cursor: pointer;
}
/* myaccount page css end */

/* print css start */
@media print {
  .header,
  .footer,
  .no_print {
    display: none !important;
  }
  .print {
    display: block !important;
  }
}
/* print css end */

.slick-track {
  height: 100% !important;
}
.search-element button:hover,
input.btn:hover {
  color: #fff !important;
  background: #deb44b !important;
}
.totop {
  z-index: 1;
  display: none;
}
input.btn {
  padding: inherit;
}
.myaccount-leftnav .nav-item {
  line-height: initial;
}
.navbar-dark .navbar-toggler {
  padding-right: 0px;
}
.navbar-dark .navbar-toggler-icon {
  //background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  background-color: #7d0633;
  /* border-radius: 0; */
  border: none;
}

/* footer css */
.footer-logo-part-wrapper p,
.footer-info-wrapper p {
  color: #fff;
}

/* toaster css */
.Toastify__toast {
  background: #7d0633 !important;
}

/* overlay loading */
.checkout-page {
  position: relative;
}
.loading_overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #ececec7a;
  z-index: 99999999999999999;
}
.loading_overlay .loading {
  height: 100%;
}

/* service page */
.md-services-promo-text p {
  max-width: 925px;
  padding: 1rem;
}
.services-slider-single .object-slider-content {
  height: 175px;
  width: 175px;
}
.services-slider-single .object-slider-content img {
  padding: 50px;
}
.services-slider-single strong {
  font-size: 18px;
}

.slick-prev:before,
.slick-next:before {
  color: white !important;
  opacity: 0.75 !important;
}

//Add New Css

.title-inner-card {
  background-image: url("../../img/inner-banner-bg.png");
  background-color: #7d0633;
  padding: 15px 20px;

  @media only screen and (max-width: 991px) {
    padding: 95px 20px 5px;
    background-size: cover;
  }
  .all-page-title {
    h2 {
      color: #ffffff;
      font-family: Crimson Text;
      font-weight: 400;
      font-size: 50px;
      line-height: 95px;
      letter-spacing: 1px;
      @media only screen and (max-width: 399px) {
        font-size: 40px;
      }
    }
  }
}
.inner-page-sub-title {
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  line-height: 53px;
  color: #000000;
  margin-bottom: 16px;
  &.font-2 {
    font-size: 38px;
    @media only screen and (max-width: 991px) {
      font-size: 30px;
    }
  }
  @media only screen and (max-width: 991px) {
    font-size: 32px;
  }
}
.clover-check {
  .form-radio {
    display: flex;
    align-items: center;
    .form-radio-input {
      width: 21px;
      height: 21px;
    }
    .form-radio-label {
      font-size: 16px;
      line-height: 21px;
      margin-bottom: 0;
      padding-left: 5px;
    }
  }
}
.payment-gateway-block {
  .App {
    padding: 50px 50px 10px;
    background-color: #f4f5f5;
    margin-top: 50px;
    @media only screen and (max-width: 420px) {
      padding: 40px 30px 10px;
    }
  }
}
.card-number,
.third-width {
  height: 75px;
}
.side-cloumn {
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 768px) {
    flex-flow: column;
  }
}
.third-width {
  max-width: 240px;
  margin-right: 10px;
  &:nth-last-child(1) {
    margin-right: 0;
  }
  @media only screen and (max-width: 768px) {
    margin-right: 0;
    max-width: 100%;
  }
}
.checkout-next-prev {
  @media only screen and (max-width: 500px) {
    display: flex;
    flex-flow: column;
  }
  .coyote-btn {
    padding: 12px 45px;
    font-size: 16px;
    font-family: "Work Sans";
  }
}

.payment-steps {
  max-width: 800px;
  margin: auto;
}
.billing-details-form {
  .columnGap {
    padding-right: 20px;
    @media only screen and (max-width: 768px) {
      padding-right: 5px;
    }
  }
  .form-group {
    .form-control {
      min-height: 52px;
    }
  }
}
.form-gap {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: 0;
  border-top: 1px dashed #b5b5b5;
}
.btn-gap {
  margin-right: 20px;
  @media only screen and (max-width: 500px) {
    margin-right: 0;
    margin-bottom: 10px;
  }
}
.container-maxWidth {
  max-width: 1124px;
  padding: 0 20px;
}
.about-sliders {
  @media only screen and (max-width: 768px) {
    padding: 0 60px;
    margin-bottom: 40px;
  }
  @media only screen and (max-width: 420px) {
    padding: 0 30px;
  }
}

//Add CSS product page
.mood-sidebar {
  background: #ffffff;
  border: 1px solid #d7d7d7;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  max-width: 324px;
  @media only screen and (max-width: 991px) {
    margin: auto;
    margin-bottom: 50px;
  }
  .nav-item {
    .nav-link {
      border-radius: 0;
    }
  }
  .sidebar-title {
    background: #7d0633;
    padding: 13px 19px;
    color: #ffffff;
    font-family: $font-family-sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
  }
  .search-element {
    margin: 36px 20px 36px 14px;
  }
  .sidebar-widget {
    margin: 30px 15px;
  }
}
.clover-footer-2 {
  padding: 25px 24px 18px;
  background-color: #f4f5f5;
  border-radius: 0 0 14px 14px;
  text-align: center;
}
.clover-secure-payments {
  color: #b1b6b8;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
}
.lock-icon {
  margin: -2px 8px;
  max-width: 12px;
}
.clover-icon {
  margin: -4px 8px;
  max-width: 20px;
}
.clover-privacy-link {
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
}
.card-error-text {
  color: red;
  font-style: italic;
  font-size: 14px;
  margin-bottom: 6px;
}
.new-cart-table {
  thead,
  tbody {
    border: 1px solid #eee;
  }
  tbody {
    tr {
      td {
        vertical-align: middle;
        max-width: 250px;
        padding-right: 30px;
        .quantity {
          text-align: center;
          width: 60px;
          border: 1px solid #7d0633;
          color: black;
          font-weight: bold;
        }

        a.product-name {
          font-size: 14px;
          text-transform: uppercase;
          font-weight: 600;
          color: #9f365d;
        }
        .thumbnail {
          img {
            max-width: 58px;
          }
        }
        .quantity-block {
          display: flex;
          align-items: center;
        }
        &.table-price {
          color: #343a40;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }
  .cart-minus,
  .cart-plus {
    color: #7d0633;
    background: none;
    border: none;
    font-size: 20px;
    padding: 10px;
    &:active,
    &:focus {
      border: 0 !important;
      outline: 0 !important;
    }
  }
  .cart-minus {
    font-size: 25px;
    padding-left: 0;
  }
}
.product-remove {
  border: none;
  color: #7d0633;
  font-size: 22px;
  padding: 5px 10px;
  &:hover {
    background: red;
    color: #ffffff;
  }
}
.preceed-checkout {
  padding: 15px 20px 35px;
  border: 1px solid #eee;
  max-width: 400px;
  margin: auto;
  .title {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
    font-weight: 600;
    margin-bottom: 25px;
  }
  table {
    width: 100%;
    tbody {
      tr {
        td,
        th {
          &:nth-last-child(1) {
            text-align: right;
          }
        }
      }
    }
  }
  .coyote-btn {
    width: 100%;
    margin: 20px 0 0;
  }
}
.cartlist-view {
  max-width: 1200px;
  .mbl-view-table {
    min-width: 320px;
    @media only screen and (max-width: 768px) {
      overflow-y: scroll;
    }
  }
}

//login page CSS
.welcome-text {
  text-align: center;
  font-weight: 600;
  font-size: 36px;
  line-height: 45px;
  color: #1c1d1f;
  margin-bottom: 40px;
}
.login-page {
  form {
    max-width: 500px;
    margin: auto;
    padding: 40px;
    border: 1px solid #dee2e6;

    .input.btn {
      min-height: 48px;
      font-size: 18px;
    }
  }
  .form-control {
    min-height: 48px;
    padding-left: 2.75rem;
    @media only screen and (max-width: 768px) {
      margin-bottom: 10px;
    }
  }
  .register {
    color: #000000 !important;
    padding-right: 5px;
    font-weight: 600;
  }
  .forgetPass {
    font-style: italic;
    padding-left: 5px;
    font-size: 14px;
    font-weight: 600;
  }
  .login-input-field {
    position: relative;
    margin-top: 20px;
    &.user {
      &::before {
        content: "";
        width: 24px;
        height: 24px;
        //background: #7d0633;
        position: absolute;
        top: 12px;
        left: 10px;
        background-image: url("../../img/user.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      }
    }
    &.lock {
      &::before {
        content: "";
        width: 24px;
        height: 24px;
        //background: #7d0633;
        position: absolute;
        top: 12px;
        left: 10px;
        background-image: url("../../img/lockk.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      }
    }
  }
}
.myaccount {
  .container {
    @media only screen and (max-width: 991px) {
      max-width: 90%;
      margin: auto;
    }
  }
  .tab-content {
    max-width: 92%;
    margin-left: auto;
    @media only screen and (max-width: 991px) {
      max-width: 100%;
      /* margin-left: 20px; */
      overflow: hidden;
      overflow-x: scroll;
    }
    table {
      thead,
      tbody {
        border: 1px solid #e9ecef;
      }
      tbody {
        tr {
          td:nth-child(2),
          td:nth-child(3) {
            font-size: 15px;
            font-weight: 500;
            color: #000000;
            min-width: 180px;
            word-break: break-word;
          }
        }
      }
    }
  }
}
.title34 {
  font-size: 20px;
  margin-bottom: 20px;
  color: #7d0633;
}
.account-info-form {
  max-width: 800px;
  margin: 20px auto;
  .form-group {
    .form-control {
      min-height: 50px;
    }
  }
  .coyote-btn {
    margin-top: 20px;
  }
}
.order-details {
  border: 1px solid #dfe0eb;
  box-sizing: border-box;
  border-radius: 5px;
  margin: 15px 0;
  .title {
    padding: 20px 28px 15px;
    background: #e9ecef;
    color: #000000;
    font-size: 18px;
    font-weight: 600;
    font-family: "Work Sans";
  }
  .details {
    padding: 20px 28px 30px;
    p {
      font-weight: 600;
      span {
        font-weight: 500;
      }
    }
  }
}
.profile-parent {
  .section-main-title {
    font-size: 25px;
    font-family: "Work Sans";
    margin: 20px auto 30px;
  }
}
.product-table {
  margin-bottom: 30px;
  thead {
    tr {
      background: #7d0633;
      color: #fff;
      font-weight: 500;
      font-family: "Work Sans";
    }
  }
}
.subtotal-table {
  float: right;
  width: 350px;
  background: #fbfbfb;
  max-width: 100%;
  margin-top: -1px;
  tbody {
    tr {
      th {
        padding-left: 20px;
      }
      td {
        text-align: right;
        padding-right: 15px;
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }
  }
}
.soldout-Tag {
  position: absolute;
  background: #d00000;
  padding: 5px 15px;
  right: 0;
  color: white;
  font-weight: 500;
}

.serviceInnerTitleBg {
  background-image: url("../../img/inner-banner-bg.png");
  background-color: #7d0633;
}

.toast-content {
  color: #fff;
  font-size: 14px;
  // border-top: 1px solid #fff;

  span {
    font-weight: bold;
  }
  a {
    color: #fff;
  }
}

.checkout-header-step-1 {
  border-bottom: 1px solid #fff;
  background-color: #7d0633;
  padding: 15px 0px;
  color: #fff;
  margin-bottom: 10px;

  a {
    color: #fff;
    text-decoration: underline;
  }
  p {
    color: #fff;
  }
}

.Toastify__toast-container--top-right {
  top: 2.4em !important;
}
.product-details .price-range ul {
  list-style: none;
  padding: 0;
  display: flex;
  // justify-content: center;
  // align-items: center;
}
.product-details .price-range ul li.off-price {
  color: #999999;
  margin-right: 5px;
}
.bg-services-carousal-area {
  background-image: url("../../img/slider/bg.png");
  background-color: #7d0633;
  width: 100%;
  display: block;
  position: relative;
  background-size: cover;
  background-position: center center;
}
.text-success {
  color: #28a745 !important;
}
.text-danger {
  color: #dc3545 !important;
}
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.pages-description-wrapper p {
  display: block;
  margin-bottom: 10px;
  text-align: justify;
}
.pages-description-wrapper img {
  margin: 0 15px 10px 15px;
}
.pages-description-wrapper b {
  // display: block;
  font-family: "Work Sans", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.2em;
  margin-bottom: 0.5rem;
}
.pages-description-wrapper ul {
  font-family: "Work Sans", sans-serif;
}

.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
}
@keyframes slideInUp {
  0% {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

/*info box*/

.box-1,
.box-2,
.box-3 {
  .img-wrapper {
    text-align: center;
    position: relative;
    i {
      display: none;
      position: absolute;
      top: 45%;
      width: 100%;
      font-size: 60px;
      z-index: 9899;
      color: #ffffff;
    }
  }
}
.box-1,
.box-2,
.box-3 {
  &:hover {
    .inf-box-wrapper .content-wrapper .icon {
      margin-bottom: 50px;
    }
    .img-wrapper {
      i {
        display: block;
      }
      &::before {
        opacity: 1;
      }
    }
    /*animation inside*/
    .btnani {
      color: #ffffff;
      transition-delay: 0.5s;
      &:before {
        transition-delay: 0s;
      }
      &:after {
        background: #deb44b;
        transition-delay: 0.35s;
      }
    }
    .from-left {
      &:before {
        width: 100%;
      }
      &:after {
        width: 100%;
      }
    }
  }
}

.btnani {
  position: relative;
  padding: 1.4rem 4.2rem;
  padding-right: 3.1rem;
  color: #000000;
  transition: all 500ms cubic-bezier(0.77, 0, 0.175, 1);
  cursor: pointer;
  user-select: none;
  z-index: 2;
  &:before {
    content: "";
    position: absolute;
    transition: inherit;
    z-index: -1;
  }
  &:after {
    content: "";
    position: absolute;
    transition: inherit;
    z-index: -1;
  }
  &:hover {
    color: #ffffff;
    transition-delay: 0.5s;
    &:before {
      transition-delay: 0s;
    }
    &:after {
      background: #deb44b;
      transition-delay: 0.35s;
    }
  }
}
.from-left {
  &:before {
    top: 0;
    width: 0;
    height: 100%;
    right: 0;
    border: 1px solid #deb44b;
    border-left: 0;
    border-right: 0;
  }
  &:after {
    top: 0;
    width: 0;
    height: 100%;
    left: 0;
  }
  &:hover {
    &:before {
      width: 100%;
    }
    &:after {
      width: 100%;
    }
  }
}

// Basic styles
.buttonani {
  border: 0;
  box-sizing: border-box;
  margin: 1em;
  padding: 1em 2em;
  box-shadow: inset 0 0 0 2px #ffffff;
  font-size: inherit;
  font-weight: 700;
  position: relative;
  vertical-align: middle;
  &::before,
  &::after {
    box-sizing: inherit;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.draw {
  transition: color 0.25s;
  &::before,
  &::after {
    border: 2px solid transparent;
    width: 0;
    height: 0;
  }
  &::before {
    top: 0;
    left: 0;
  }
  &::after {
    bottom: 0;
    right: 0;
  }
  &:hover {
    color: #f00;
  }
  // Hover styles
  &:hover::before,
  &:hover::after {
    width: 100%;
    height: 100%;
  }
  &:hover::before {
    border-top-color: #deb44b;
    border-right-color: #deb44b;
    transition: width 0.25s ease-out, height 0.25s ease-out 0.25s;
  }
  &:hover::after {
    border-bottom-color: #deb44b;
    border-left-color: #deb44b;
    transition: border-color 0s ease-out 0.5s, width 0.25s ease-out 0.5s,
      height 0.25s ease-out 0.75s;
  }
}

.product-container {
    margin: 0 0 150px 0;

    .product-container-slider {
        width: 1280px;
        margin: auto;

        .no-data-found {
            font-weight: bold;
            color: #ff6c00;
            font-size: 25px;
        }

        .slick-slider {
            overflow: visible;

            @media only screen and (max-width: 991px) {
                overflow: hidden;
            }
        }

        @media only screen and (max-width: 991px) {
            width: 100%;
            margin: 0 0 50px 0;
        }

        .slick-prev,
        .slick-next {
            background-repeat: no-repeat;
            background-size: auto;
            background-position: left center;
            width: 22px;
            height: 40px;
            z-index: 1;

            &::before {
                display: none;
            }
        }

        .slick-prev.slick-disabled,
        .slick-next.slick-disabled {
            opacity: 0.4;
            cursor: no-drop;
        }

        .slick-prev {
            background-image: url("../../img/product/left-arrow.png");
            left: -60px;

            @media only screen and (min-width: 1024px) and (max-width: 1400px) {
                left: -20px;
            }

            @media only screen and (max-width: 991px) {
                left: 10px;
            }
        }

        .slick-next {
            background-image: url("../../img/product/right-arrow.png");
            right: -60px;

            @media only screen and (min-width: 1024px) and (max-width: 1400px) {
                right: -20px;
            }

            @media only screen and (max-width: 991px) {
                right: 10px;
            }
        }

        .slick-list {
            width: 1280px;
            margin: auto;

            @media only screen and (max-width: 991px) {
                width: 100%;
            }
        }

        .product-wrapper {
            padding: 0 12px;

            .product-content {
                border: 1px solid #f2f2f2;
                background-color: #f8f8f8;
                position: relative;
                height: 552px;

                a {
                    position: absolute;
                    z-index: 1;
                    width: 100%;
                    height: 100%;
                    top: 0;
                }

                .product-image {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 25px;
                    height: 300px;
                    border-bottom: 1px solid #f2f2f2;

                    img {
                        height: auto;
                        width: 95%;
                        object-fit: inherit;
                    }
                }

                .product-info {
                    text-align: center;
                    padding: 25px 23px 50px 23px;
                    height: 254px;

                    h3 {
                        font-size: 18px;
                        color: #202020;
                        text-transform: uppercase;
                        margin: 0 0 20px 0;
                        line-height: 28px;
                    }

                    p {
                        margin: 0;
                        padding: 0 0 28px 0;
                        color: #505050;
                        font-size: 12px;
                        line-height: 20px;
                    }

                    span {
                        color: #202020;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 20px;
                        position: absolute;
                        bottom: 18px;
                        width: 100%;
                        left: 0;
                    }
                }
            }
        }
    }
}

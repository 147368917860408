.section-title {
    position: relative;
    font-size: 50px;
    font-weight: 400;
    text-transform: uppercase;
    color: $white;
    margin-bottom: 40px;
    text-align: center;
    font-family: 'Comic Neue', cursive;

    &:before {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 0px;
        height: 3px;
        width: 60px;
        left: 50%;
        margin-left: -30px;
        background: rgba($white, 0.8);
    }
}

.bg-light {
    background: $light-bg;
}

.divider {
    height: 1px;
    background: $tmpcolor;
}

.transition {
    transition: all .4s ease-in-out;
}

.hyperlink {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
}

a {
    @extend .transition;

    &:focus {
        outline: 0;
        outline: none;
    }
}

.wow {
    visibility: hidden;
}

.border-bottom {
    border-bottom: 1px solid #555555;
}

.bg-cover {
    background-size: cover;
}

.bg-contain {
    background-size: contain;
}

.bg-fixed {
    background-attachment: fixed;
}
body {
  overflow-x: hidden;
}

/*** top-header S***/
iframe {
  /*display: none !important;*/
}

.top-header {
  padding: 0 62px;
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;

  @media only screen and (max-width: 1280px) {
    padding: 0 15px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
  }

  .nav-social {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
    padding: 0;

    li {
      margin: 0 20px 0 0;

      a {
        color: #fff;
        transition: 0.5s;

        &:hover {
          color: #c5c4c4;
          transition: 0.5s;
        }
      }
    }
  }

  .userNav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0;
    padding: 0;

    li {
      &.shopping-cart {
        display: block;
        border-style: solid;
        border-color: white;
        border-width: 0 0 0 2px;
        padding: 0 0 0 8px;
        margin: 0 0 0 8px;
        position: relative;
        line-height: 14px;

        span {
          position: absolute;
          top: -7px;
          background-color: #ffffff;
          font-size: 11px;
          width: 16px;
          height: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          right: -14px;
          border-radius: 100%;
          border: 1px solid #000;
        }
      }

      &.auth-login-container{

        a{

          i{
            margin: 0 6px 0 0;
          }
        }
      }

      a {
        color: #ffffff;

        span {
          border: 0px;
          padding: 0;
          margin: 0;
        }
      }
    }
  }
}

/*** top-header E***/

.mood-nav {
  &.navbar-expand-lg {
    .navbar-collapse {
      justify-content: center;

      ul {
        align-items: center;

        li {
          @media only screen and (max-width: 991px) {
            width: 100%;
          }

          &.logo-nav-item {
            @media only screen and (max-width: 991px) {
              display: none;
            }
          }
        }
      }

      .logo-container {
        margin: 0 155px;
      }
    }
  }
}

.header {
  @media only screen and (max-width: 991px) {
    position: fixed;
    width: 100%;
    background-color: #ffffff;
    z-index: 100;
    top: 38px;
  }

  .navbar-brand {
    padding: 0 0;
    img {
      max-width: 100%;
      @media only screen and (max-width: 1199px) {
        max-width: 120px;
      }
      @media only screen and (max-width: 399px) {
        max-width: 80px !important;
      }
    }
  }

  .navbar-light {
    background: #f00 !important;
    padding: 5px 0;
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.1);
  }

  .sticky-navbar {
    .nav-link {
      padding: 1rem 0.5rem;
    }
  }
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,0,0, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  border: 1px solid black;
  border-radius: 4px;
  padding: 18px;
}
.navbar-toggler {
  border-color: transparent;
  span.shopping-cart {
  }
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: $white !important;
  border-bottom: 2px solid $tmpcolor;
}

.navbar-light .navbar-toggler {
  color: $tmpcolor;
  border-color: $tmpcolor;
  background: $white;
  border-radius: 0;
}

.mobile-logo {
  img {
    max-width: 100px;
  }
}
/*@dev - p*/
.mood-nav {
  padding: 7px 62px;

  .navbar-nav {
    .nav-icons {
      &.mobile {
        display: none;
      }
    }

    li {
      a.nav-link {
        font-family: Roboto;
        font-weight: 500;
        font-size: 18px;
        color: #010101;
        padding-right: 20px;
        padding-left: 20px;

        &:hover {
          color: $tmpcolor !important;
        }

        &.active {
          color: $tmpcolor;
        }

        @media only screen and (max-width: 1360px) {
          padding-right: 10px;
          padding-left: 10px;
          font-size: 17px;
        }

        @media only screen and (max-width: 1140px) {
          padding: 8px;
          font-size: 14px;
        }
      }
    }
  }
  /*icons*/
  .icon-wrapper {
    .nav-icons {
      margin: 0 58px 0 0;
      padding: 0;
      list-style: none;
      display: flex;
      border-right: 1px solid #dddddd;
      align-items: center;

      @media only screen and (max-width: 1451px) {
        margin: 0 10px 0 0;
      }

      li {
        font-size: 20px;
        padding: 4px;
        margin-right: 10px;
      }

      li.shopping-cart {
        font-size: 24px;
        position: relative;
        margin-right: 30px;

        a {
          color: #000000;
        }
        span {
          position: absolute;
          top: 1px;
          border-radius: 50%;
          background: #000000;
          color: #ffffff;
          padding: 3px;
          font-size: 12px;
          width: 22px;
          height: 22px;
          line-height: 1.3;
          text-align: center;
          margin-right: 40px;
        }
      }
    }
    .nav-social {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      li {
        font-size: 20px;
        padding: 4px;
        margin-right: 28px;
        @media only screen and (max-width: 1451px) {
          margin-right: 10px;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.nav-icons {
  &.mobile {
    display: none;
  }
}

.banner-slider {
  .container-fluid {
    padding: 0;
    .slider-item {
      padding: 225px 0 150px;
    }
  }
}

.banner-content {
  position: absolute;
  top: 17%;
  left: 9%;
  width: 44%;
  h1 {
    font-family: "Crimson Text", serif;
    font-size: 100px;
    font-weight: 700;
    color: #ffffff;
    line-height: 1.1;
    span {
      color: $tmpdark;
    }
  }
  h2 {
    font-family: "Crimson Text", serif;
    font-size: 60px;
    color: #ffffff;
    line-height: 80px;
  }
  p {
    font-family: "Work Sans", sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.8;
    color: #ffffff;
    margin-top: 10px;
    margin-bottom: 60px;
  }
  a {
    font-family: Roboto;
    font-weight: 400;
    font-size: 20px;
    border-radius: 35px;
    border: 2px solid #deb44b;
    color: white;
    background: transparent;
    padding: 20px 30px;
    transition: all 0.5s ease-in-out;
    &:hover {
      background: #deb44b;
    }
  }
}

@media (min-width: 1600px) and (max-width: 2000px) {
  .container {
    max-width: 1520px;
  }
}
.userNav {
  li {
    a {
      span {
        font-size: 14px;
        font-weight: 600;
        font-family: "Work Sans";
        padding: 1px 5px;
        margin-left: 8px;
        border-left: 2px solid;
        line-height: 50px;
        @media only screen and (max-width: 399px) {
          font-size: 12px;
          padding: 1px 2px;
          margin-left: 5px;
        }
      }
    }
  }
}

.mob-logo-container {
  display: none;

  @media only screen and (max-width: 991px) {
    display: block;
  }
}

.userNav-mbl {
  @media only screen and (max-width: 991px) {
    display: none !important;
  }
}

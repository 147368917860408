/* slick  Dots */
.slick-dotted.slick-slider
{
    margin-bottom: 30px;
}

.slick-dots
{
    position: absolute;
    bottom: -40px;

}
.slick-dots li
{
    width: 15px;
    height: 7px;
}
.slick-dots li button
{
    width: 15px;
    height: 7px;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}
.slick-dots li button:before
{
    width: 14px;
    height: 6px;
    content: '';
    opacity: 1;
    background-color: $white;
    border: 0 solid $white;;

}
.slick-dots li.slick-active button:before
{
    opacity: 1;
    background-color: $tmpcolor;
}

.slick-slide:focus {
    outline: 0;
}

.slick-slider {
    user-select: text;
}
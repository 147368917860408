/*Page Intro*/
.mood-page-intro {
  padding: 150px 0 150px;
  .img-wrapper {
  }
  .content-wrapper {
    h2 {
      font-family: "Crimson Text", serif;
      font-size: 40px;
      font-weight: 700;
      color: #414141;
      margin-bottom: 35px;
    }
    p {
      font-family: "Work Sans", sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.6;
      color: $body-color;
      margin-bottom: 55px;
      text-align: justify;
      padding: 0 20% 0 0;
    }
    a {
      font-family: Roboto;
      font-weight: 400;
      font-size: 18px;
      border-radius: 35px;
      border: 2px solid #deb44b;
      color: #ffffff;
      background: $tmpdark;
      padding: 18px 42px;
      &:hover {
        background: #c0993e;
        border: 2px solid #c0993e;
        padding: 18px 50px;
        box-shadow: -1px 1px 9px 2px rgba(222, 180, 75, 0.75);
        -webkit-box-shadow: -1px 1px 9px 2px rgba(222, 180, 75, 0.75);
        -moz-box-shadow: -1px 1px 9px 2px rgba(222, 180, 75, 0.75);
      }
    }
  }
}
@media (max-width: 1024px) {
  .mood-page-intro {
    padding: 75px 0 75px;
  }
  .mood-info-box .inf-box-wrapper .content-wrapper .icon {
    width: 100%;
    margin-bottom: 15px;
    transition: all 0.5s ease-in;
    margin-top: 15px;
  }
}

.mood-info-box {
  overflow: hidden;
  transition: all 0.5s ease-in;
  .inf-box-wrapper {
    .content-wrapper {
      background: #0d0c0e;
      text-align: center;
      min-height: 432px;
      display: flex;
      flex-direction: column;
      align-content: center;
      flex-flow: row wrap;
      .icon {
        width: 100%;
        margin-bottom: 40px;
        transition: all 0.5s ease-in;
      }
      .content-title {
        margin-bottom: 10px;
        width: 100%;
        h3 {
          font-family: "Crimson Text", serif;
          font-size: 40px;
          color: #ffffff;
        }
      }
      .content-text {
        padding: 15px 65px;
        width: 100%;
        p {
          font-family: "Work Sans", sans-serif;
          font-weight: 400;
          font-size: 16px;
          color: #ffffff;
        }
      }
    }
    .img-wrapper {
      position: relative;
      &::before {
        content: "";
        background: rgba(0, 0, 0, 0.7);
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        bottom: 0;
        right: 0;
        opacity: 0;
        -webkit-transition: all 0.4s ease-in-out 0s;
        -moz-transition: all 0.4s ease-in-out 0s;
        transition: all 0.4s ease-in-out 0s;
      }
      img {
        width: 100%;
      }
    }
    &.middle {
      display: flex;
      flex-direction: column-reverse;
      .content-wrapper {
        background: #7d0633;
        min-height: 440px;
      }
    }
  }
}

.mood-products {
  padding: 150px 0;
  .product-wrapper {
    text-align: center;
    border: 1px solid #ffffff;
    &:hover {
      border: 1px solid #e2dfdf;
      -webkit-transition: all 0.5s ease-in-out 0s;
      -moz-transition: all 0.5s ease-in-out 0s;
      transition: all 0.5s ease-in-out 0s;
      box-shadow: -1px -1px 11px 1px rgba(113, 103, 103, 0.75);
      -webkit-box-shadow: -1px -1px 11px 1px rgba(113, 103, 103, 0.75);
      -moz-box-shadow: -1px -1px 11px 1px rgba(113, 103, 103, 0.75);
    }
    .img-wrapper {
    }
    .content-wrapper {
      h4 {
        font-family: "Work Sans", sans-serif;
        font-size: 18px;
        font-weight: 600;
        color: #404040;
        line-height: 1.5;
        margin-bottom: 10px;
      }
      p {
        font-family: "Work Sans", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.2;
        margin-bottom: 42px;
      }
      a {
        font-family: Roboto;
        font-weight: 400;
        font-size: 18px;
        border-radius: 35px;
        border: 2px solid #deb44b;
        color: #ffffff;
        background: $tmpdark;
        padding: 18px 42px;
        &:hover {
          background: #c0993e;
          border: 2px solid #c0993e;
          padding: 18px 50px;
          box-shadow: -1px 1px 9px 2px rgba(222, 180, 75, 0.75);
          -webkit-box-shadow: -1px 1px 9px 2px rgba(222, 180, 75, 0.75);
          -moz-box-shadow: -1px 1px 9px 2px rgba(222, 180, 75, 0.75);
        }
      }
    }
  }
}

.mood-testimonial {
  background: #0d0c0e;
  padding: 150px 0;
  text-align: center;
  h2 {
    font-family: "Crimson Text", serif;
    font-size: 40px;
    color: #ffffff;
    margin-bottom: 45px;
  }
  .testimonial-sliders {
    text-align: center;
    color: #ffffff;
    ul {
      margin-bottom: 35px;
      list-style: none;
      padding: 0;
      display: inline-flex;
      li {
        margin: 0 3px;
      }
    }
    p {
      font-family: "Work Sans", sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 30px;
      margin-bottom: 35px;
      padding: 0 8%;
    }
    h3 {
      font-family: "Work Sans", sans-serif;
      font-size: 18px;
      font-weight: 600;
    }
  }
}

.mood-partner {
  padding: 90px 0 70px;
  display: flex;
  align-items: center;
  img {
    margin: 0 auto;
  }
  .slick-slide{
    img {
      display: block;
      margin: 0 auto;
    }
  }
  .slick-track .slick-slide {
    display: flex;
    height: auto;
    align-items: center;
    justify-content: center;
  }
  .slick-track {
    display: flex;
  }
}

//Instagram
.overlap_background {
  background: #ffffff;
  position: absolute;
  margin-top: -58px;
  z-index: 9999999;
  width: 100%;
  height: 60px;
}
.instagram-feed-area {
  margin: 20px 0px;
  position: relative;
  @media only screen and (max-width: $scr-xs-max) {
    margin: 0px;
  }
  .instagram-feed-images-wrapper {
    #sb_instagram {
      pointer-events: none;
      width: 100%;
      margin: 0 auto;
      padding: 0;
      padding-bottom: 10px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      .sbi_photo_wrap {
        position: relative;
      }
      .sbi_photo {
        display: block;
        text-decoration: none;
      }
      #sbi_images {
        width: 100%;
        float: left;
        line-height: 0;
        padding: 5px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        .sbi_item {
          display: -moz-inline-stack;
          display: inline-block;
          float: left;
          vertical-align: top;
          zoom: 1;
          max-height: 1000px;
          padding: inherit !important;
          margin: 0 !important;
          text-decoration: none;
          opacity: 1;
          overflow: hidden;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          -webkit-transition: all 0.5s ease;
          -moz-transition: all 0.5s ease;
          -o-transition: all 0.5s ease;
          -ms-transition: all 0.5s ease;
          transition: all 0.5s ease;
          .sbi_photo_wrap {
            a {
              width: 300px;
              height: 300px;
              background-size: cover;
              background-position: center center;
              background-repeat: no-repeat;
              @media (min-width: $scr-sm-min) and (max-width: $scr-lg-max) {
                max-width: 100%;
                height: 220px;
              }
              @media only screen and (max-width: $scr-xs-max) {
                width: 100%;
                height: 300px !important;
              }
            }
          }
        }
      }
    }
    #sb_instagram.sbi_col_6 {
      #sbi_images {
        .sbi_item {
          width: 16.66%;
          @media (min-width: $scr-sm-min) and (max-width: $scr-md-max) {
            width: 33.31%;
          }
        }
      }
    }
  }
}
.eapps-instagram-feed {
  .eapps-link {
    display: none;
  }
}

.mobile-view {
  display: none;
  @media only screen and (max-width: $scr-xs-max) {
    display: block;
  }
  #sb_instagram.sbi_width_resp {
    width: 100% !important;
  }
  #sb_instagram.sbi_col_1 #sbi_images .sbi_item {
    width: 100%;
  }
}
.desktop-view {
  @media only screen and (max-width: $scr-xs-max) {
    display: none;
  }
}
.instagram-icon-wrapper {
  position: absolute;
  top: 40%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  z-index: 1;
  .instagram-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    color: #fff;
  }
  .circle-design-wrapper {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #393535;
    @media (min-width: $scr-sm-min) and (max-width: $scr-lg-max) {
      width: 170px;
      height: 170px;
    }
    .instagram-title {
      font-size: 20px;
      font-weight: bold;
      @media (min-width: $scr-sm-min) and (max-width: $scr-lg-max) {
        font-size: 18px;
      }
    }
    .instagram-id {
      font-size: 18px;
      font-weight: normal;
      @media (min-width: $scr-sm-min) and (max-width: $scr-lg-max) {
        font-size: 16px;
      }
    }
    i {
      font-size: 30px;
      margin: 5px 0;
      color: #fff;
      line-height: 1;
    }
  }
}
a.eapps-link {
  display: none !important;
}
#eapps-instagram-feed-1 .eapps-link {
  display: none !important;
}

//Newsletter
.newsletter-area {
  padding: 30px 0;
  background: #7d0633;
  color: #fff;
  .newsletter-wrap {
    .newsletter-title {
      font-size: 40px;
      line-height: 30px;
      font-family: "Crimson Text", serif;
      font-weight: 900;
      padding-left: 15px;
      @media (min-width: $scr-sm-min) and (max-width: $scr-lg-max) {
        font-size: 34px;
      }
    }
    @media only screen and (max-width: $scr-xs-max) {
      display: flow-root !important;
      text-align: center;
      padding: 10px 20px;
    }
    .newsletter-text {
      font-size: 18px;
      line-height: 30px;
      color: #ffffff;
      font-weight: 500;
      font-family: 'Work Sans';
      text-align: center;
      @media (min-width: $scr-sm-min) and (max-width: $scr-lg-max) {
        font-size: 15px;
      }
      
      @media (min-width: $scr-sm-min) and (max-width: $scr-md-max) {
        width: 320px;
      }
      @media only screen and (max-width: $scr-xs-max) {
        line-height: 25px;
        padding: 20px 0px;
      }
    }
    .signup-btn-box {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 160px;
      flex: 0 0 160px;
      .btn-signup {
        display: inline-block;
        background-color: #ffffff;
        padding: 5px 35px;
        border-radius: 50px;
        line-height: 50px;
        font-size: 20px;
        font-family: Roboto;
        cursor: pointer;
        color: #7d0633;
        font-weight: 500;
        border: 2px solid #7d0633;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        min-width: 180px;
        cursor: pointer;
        @media (min-width: $scr-sm-min) and (max-width: $scr-lg-max) {
          padding: 0px 15px;
          min-width: 150px;
        }
        &:focus {
          outline: none;
        }
        &:hover {
          background-color: #7d0633;
          color: #ffffff;
          border: 2px solid #fff;
        }
      }
    }
  }
}

/*new full width slider*/

body {
  margin: 0;
}
.slick-slider,
.slick-list,
.slick-track {
  height: 100%;
}
.slick-slider {
  overflow: hidden;
  button {
    cursor: pointer;
  }
  .slick-track {
    .slick-slide {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
.services, .shop-category-area{
  //padding-top: 100px;
}
.homeSlider {
  //padding-top: 100px;
  @media only screen and (max-width: 991px) {
    padding-top: 58px;
  }
  @media only screen and (max-width: 756px) {
    padding-top: 55px;
  }
  .slick-prev {
    left: 45px;
    z-index: 1;
  }
  .slick-next {
    right: 50px;
    z-index: 1;
  }
  .slick-prev:before,
  .slick-next:before {
    font-size: 42px;
  }
  .slick-dots {
    bottom: 0;
  }
  .slick-list {
    padding-bottom: 40px;
  }
}

/* for info box*/

.section {
  flex: 1; /*grow*/
  border: 1px solid;
}

@media (max-width: 768px) {
  /*breakpoint*/
  .container {
    flex-direction: column;
  }
}
.slick-carousel {
  margin: 0 auto;
  width: 1000px;
}

/* The width of each slide */
.slick-slide {
  width: 350px;
}

/* Color of the arrows */
.slick-next::before,
.slick-prev::before {
  color: blue;
}

/* search - expand */
input {
  outline: none;
  &::-webkit-search-decoration {
    display: none;
  }
  &::-webkit-search-cancel-button {
    display: none;
  }
  &:-moz-placeholder {
    color: #999;
  }
  &::-webkit-input-placeholder {
    color: #999;
  }
}
input[type="search"] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  font-family: inherit;
  font-size: 100%;
  background: #ffffff url(../../img/search-icon.jpg) no-repeat 4px center;
  border: solid 1px transparent;
  padding: 9px 10px 9px 32px;
  width: 55px;
  border-radius: 10em;
  transition: all 0.5s;
  &:focus {
    width: 130px;
    background-color: #fff;
    border-color: $tmpcolor;
    box-shadow: 0 0 5px rgba(109, 207, 246, 0.5);
  }
}
.search-expand {
  input[type="search"] {
    width: 15px;
    padding-left: 10px;
    color: transparent;
    cursor: pointer;
    &:hover {
      background-color: #fff;
    }
    &:focus {
      width: 130px;
      padding-left: 32px;
      color: #000;
      background-color: #fff;
      cursor: auto;
    }
  }
  input {
    &:-moz-placeholder {
      color: transparent;
    }
    &::-webkit-input-placeholder {
      color: transparent;
    }
    &:before {
      content: "\f002";
      font-family: FontAwesome;
      left: -5px;
      position: absolute;
      top: 0;
    }
    position: relative;
  }
}

.pages-details{
 // padding-top: 150px;
  @media only screen and (max-width: 991px) {
    //padding-top: 110px;
  }
}
/*-------------------------------
		| CREATE CONTENT |
---------------------------------*/
.forgot-password-form {
  background: rgba(84, 84, 85, 0.1);
  border-radius: 6px;
}
.contact-us {
  padding: 20px 0 60px;
  .mood-img{
    @media only screen and (max-width: 768px) {
    display: none;
    }
  }
  img {
    margin-bottom: 40px;
  }
  .address-section {
    margin-bottom: 75px;
    @media only screen and (max-width: 767px) {
      margin-bottom: 50px;
      }
    h3 {
      color: #7d0633;
    text-transform: uppercase;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 23px;
    }
    h6.address {
      font-size: 16px;
      line-height: 25px;
      margin-bottom: 20px;
      color: rgb(51, 51, 51);
    }
    .email,
    .phone {
      margin-bottom: 8px;
      font-size: 16px;
      font-weight: 500;
    }
  }
  .office-time {
    margin-bottom: 40px;
    h4 {
      color: #7d0633;
    text-transform: uppercase;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 23px;
    }
    h6 {
      font-size: 16px;
      line-height: 1.8;
      color: rgb(51, 51, 51);
      span {
        font-size: 16px;
        width: 100px;
        display: inline-block;
      }
      .notice {
        width: 100%;
        color: #f16725;
        margin: 15px 0;
        font-weight: 600;
        font-style: italic;
        font-size: 14px;
      }
    }
  }
  label {
    font-size: 16px;
    font-weight: 500;
  }
}
